(function() {
"use strict"
// =========================================================

window.winnerSelectExportLimitBundleSrc = function() {
	const host = window.location.host
	const bundleSrc = 
        host.startsWith('localhost:8000') ? 'http://' + host + '/script.js' :
        'https://' + (
            !( host.startsWith('alpha') || host.startsWith('localhost') ) ? '' : 'alpha0.'
        ) + 'baza-winner.ru/metric_w7_select_export_limit/script.js'
    return bundleSrc
}

window.winnerSelectExportLimitBundleId = function() {
    return 'winnerSelectExportLimit'
}

window.getW7SelectExportLimit = function(value) {
    const bundleSrc = winnerSelectExportLimitBundleSrc()
	const bundleId = winnerSelectExportLimitBundleId()
	const asyncClosure = () => 
		new Promise((resolve, reject) => {
			const ret = window._getW7SelectExportLimit(value)
			if (ret.error) {
				reject(ret.error)
			} else {
				resolve(ret)
			}
		})
	const alwaysReload = true
	return window.lazyLoadAsyncRun({bundleId, bundleSrc, asyncClosure, alwaysReload})
}

// =========================================================
})()
